import React from 'react';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
import { get } from 'lodash';
import { graphql } from 'gatsby';
import App from '../components/App';
import Container from '../components/Container';
import HeroBlog from '../components/HeroBlog';
import SEO from '../components/SEO';
import Testimonial from '../components/Testimonial';
import CallToActionSlim from '../components/CallToActionSlim';
import HeroInterior from '../components/HeroInterior';

export default function CaseStudyDetail({ data }) {
  const {
    contentfulCaseStudy,
    contentfulCallToAction,
  } = data;
  const {
    body,
    leader,
    testimonials,
    pdf,
    companyLogo,
  } = contentfulCaseStudy;
  const html = get(body, 'childContentfulRichText.html');
  const stringifiedBody = JSON.stringify(html);
  const lastParagraphIndex = stringifiedBody.lastIndexOf('<h3>');
  const beforeLastParagraph = stringifiedBody.substring(1, lastParagraphIndex);
  const lastParagraph = stringifiedBody
    .substring(0, stringifiedBody.length - 1)
    .substring(lastParagraphIndex);

  return (
    <App>
      <SEO {...contentfulCaseStudy} />
      <HeroInterior version="purple" {...contentfulCaseStudy} />
      <div style={{ marginTop: '-5vh', textAlign: 'center' }}>
        <Container
          small
          border
          style={{
            background: '#FFF',
            maxWidth: '92%',
          }}
        >
          <Image
            {...companyLogo}
            srcStyle={{
              mixBlendMode: 'multiply',
            }}
            style={{
              width: '150px',
              margin: '1rem auto 2rem',
            }}
          />
          <h2>About the Company</h2>
          <p>{get(leader, 'leader')}</p>
        </Container>
      </div>
      {testimonials[0] && <Testimonial {...testimonials[0]} />}
      <Container small>
        <div dangerouslySetInnerHTML={{ __html: beforeLastParagraph }} />
      </Container>
      {testimonials[1] && <Testimonial {...testimonials[1]} />}
      <Container small>
        <div dangerouslySetInnerHTML={{ __html: lastParagraph }} />
        {pdf && (
          <a
            href={get(pdf, 'file.url')}
            title={get(pdf, 'title')}
            target="_blank"
            rel="noopener noreferrer"
          >
            {'Download PDF'}
          </a>
        )}
      </Container>
      <Container>
        <CallToActionSlim {...contentfulCallToAction} />
      </Container>
    </App>
  );
}

CaseStudyDetail.propTypes = {
  data: PropTypes.shape({
    contentfulCaseStudy: PropTypes.object,
  }).isRequired,
};

export const query = graphql`

    ## excuse the type in the content type.
    ## it was realized too late.
    fragment TestimonialEntry on ContentfulTestimonal {
        name 
        company 
        title 
        contentful_id 
        blockquote {
            blockquote
        }
    }

    query getCaseStudyByContentfulID($contentful_id: String) {
        contentfulCaseStudy(contentful_id: { eq: $contentful_id }) {
            title
            companyLogo {
                alt: title 
                fluid(maxWidth: 250){
                    ...GatsbyContentfulFluid
                }
            }
            leader {
                leader
            }
            body {
                childContentfulRichText {
                    html
                }
            }
            pdf {
                title 
                file {
                    url
                }
            }
            testimonials {
                ...TestimonialEntry
            }
        }
        contentfulCallToAction(contentful_id: { eq: "6HwnBH5whfGY01J3bfnPE0" }){
            title 
            body {
                childContentfulRichText {
                    html
                }
            }
            buttonLabel 
            buttonDestination
        }
    }
`;
